<template>
  <el-drawer :title="label" :visible="visible" @close="visible = false">
    <div class="component-container">
      <el-form label-width="100px" :model="dataForm">
        <el-row>
          <el-col :span="12">
            <el-form-item label="编号">
              {{ data.Code }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="姓名">
              <span v-if="data.Member">{{ data.Member.Name }}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="性别">
              <div v-if="data.Member">{{ data.Member.Sex }}</div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="年龄">
              <div v-if="data.Member">
                {{
                  format.getAgeByDateString(
                    format.dateFormat(data.Member.Birthday)
                  )
                }}
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="判定分层">
              <el-tag v-if="data.Member.AcceptableStatus == 20" type="danger"
                >高危</el-tag
              >
              <el-tag v-else type="success"> 低危 </el-tag>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="预约时间">
              <el-tag type="success"
                >{{ format.dateFormat(data.AppointDate) }}/{{
                  data.AppointTime
                }}点</el-tag
              >
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-form label-width="100px" :model="dataForm">
        <el-form-item :label="desLabel">
          <el-input
            type="textarea"
            :rows="6"
            v-model="dataForm.desc"
            placeholder="请输入"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="warning" @click="visible = false">关闭</el-button>
          <el-button type="success" @click="submit">确定</el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-drawer>
</template>
<script>
import { updateCheckStatus } from "@/api/check/check.js";
export default {
  data() {
    return {
      visible: false,
      data: [],
      row: [],
      dataForm: {},
      label: "操作",
      desLabel: "操作说明",
    };
  },
  methods: {
    show(row, status) {
      if (status == "Cancel") {
        this.label = "取消";
        this.desLabel = "取消理由";
      }
      if (status == "Refuse") {
        this.label = "拒绝";
        this.desLabel = "就医建议";
      }
      if(status=="Pass"){
        this.label="通过"
      }
      this.data = row;
      this.dataForm = { key: "", status: "", desc: "" };
      this.dataForm.key = this.data.Key;
      this.dataForm.status = status;
      this.dataForm.desc = "";
      this.visible = true;
    },
    submit() {
      if (!this.dataForm.desc) {
        if (this.dataForm.status == "Cancel") {
          this.msg.errorMsg("请输入取消理由！");
          return;
        }
        if (this.dataForm.status == "Refuse") {
          this.msg.errorMsg("请输入就医建议！");
          return;
        }
      }
      updateCheckStatus(this.dataForm)
        .then((res) => {
          this.visible = false;
          this.$emit("update");
        })
        .catch((ex) => {
          this.msg.errorMsg(ex);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.component-container {
  padding: 20px;
  box-sizing: border-box;
}
.pagination {
  text-align: right;
  margin-top: 10px;
}
.tab-content {
  margin-top: -2px;
  border: 1px solid #dcdfe6;
  border-top: 0;
  padding: 15px;
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);
}
/deep/.el-drawer__header {
  margin-bottom: 5px;
  font-size: 16px;
}
:deep(.el-drawer__header) {
  margin-bottom: 10px;
  font-size: 16px;
  color: #ff862f !important;
}
:deep(.el-drawer__body) {
  overflow-y: scroll !important;
}
</style>